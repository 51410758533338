<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
  <div class="height-div pt-16">
    <transition name="slide-fade">
      <div id="aliansas" class="container-medium " v-if="animation1"> 
        <p class="title-2 "> Yipi Galería</p>
        <swiper class="swiper-slide" :options="swiperOption"  >
         
          <swiper-slide>  
              <img  class="img-galery" loading="lazy" 	 
              @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455106/yipi/20200807_190115_oceeap.jpg')"
              	src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455106/yipi/20200807_190115_oceeap.jpg"/> 
          </swiper-slide>

      
          <swiper-slide >  
              <img  class="img-galery" loading="lazy" 
              @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455604/yipi/IMG_4033_it1kmq.jpg')"
              src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455604/yipi/IMG_4033_it1kmq.jpg"/> 
          </swiper-slide>

          <swiper-slide>  
              <img  class="img-galery" loading="lazy"
                @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455815/yipi/IMG_4064_vc2k98.jpg')"
               src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644455815/yipi/IMG_4064_vc2k98.jpg"/> 
          </swiper-slide>

          <swiper-slide>  
              <img  class="img-galery" loading="lazy" 
              @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456029/yipi/IMG_4073_fljany.jpg')"
              src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456029/yipi/IMG_4073_fljany.jpg"/> 
          </swiper-slide>

        <swiper-slide>  
          <img  class="img-galery" loading="lazy" 
          @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456344/yipi/yipicarro_1_bs8hnq.jpg')"
            src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456344/yipi/yipicarro_1_bs8hnq.jpg"/> 
        </swiper-slide>

        <swiper-slide> 
          <img  class="img-galery" loading="lazy" 
          @click="showFullScreen('https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456342/yipi/yipicarro2_s9ypsn.jpg')"
            src="https://res.cloudinary.com/dqvqk71l3/image/upload/c_scale,w_836/v1644456342/yipi/yipicarro2_s9ypsn.jpg"/> 
        </swiper-slide>
       
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

      </div>
    </transition>

    <div class="img-full-screen" v-if="imageVisibility" @click="unshowFullScreen" >
        <img  class="image-full" :src="imageUrl" @click="unshowFullScreen"/>

    </div>
</div>
</Intersect>
</template>


<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

// Import Swiper styles
import "swiper/css/swiper.css";
import Intersect from 'vue-intersect';

export default {
  name: "slider-image",
  components: {
    Swiper,
    SwiperSlide,
    Intersect
  },
  data() {
    return {
      imageUrl:'',
      imageVisibility:false,
           animation1:false,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints :{
       
           640:{
                  slidesPerView: 3,
                   spaceBetween: 0,
           }
         },
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
         
      },
    };
  },
  directives: {
    swiper: directive,
  },
  methods: {
    showFullScreen(url){
      this.imageUrl=url
      this.imageVisibility=true;
    },
    unshowFullScreen(){
      this.imageUrl=''
      this.imageVisibility=false;
    }
  },
};
</script>

<style scoped>
.img-galery{
position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
width: 415px !important;
height: 510px !important;
cursor: pointer;
border-radius: 40px;
}
.height-div-big{
height: 760px !important;
display: flex;
align-items: center;
}
.image-full{
  width: 90%;
  height: 90%;
  object-fit: contain;
  position: absolute;
}
.img-full-screen{
   position: fixed;
  left: 0px;
  top: 0px;
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(187, 183, 183, 0.527);
  width: 100%;
  height: 100%;
}
.img-galery{
  width: 100%;
  height: 100%;
  object-fit: cover;
    background-size: 100% 100%;
}
    .container-medium{
  
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        align-items: center;
        justify-content: center;
    }

.swiper-container {
  height: 550px; /*active */

}
@media(max-width: 400px){
  .swiper-container {
  height: 500px; /*active */

}
.img-galery{
position: absolute;
margin-left: 0;
margin-right: 0;
left: 0;
right: 0;
text-align: center;
width: 100% !important;
height: 450px;
border-radius: 20px;

}
.image-full{
  width: 100%;
  height: auto;
  padding: 15px;
  object-fit: contain;
  position: absolute;
}
.height-div-big{
margin-top: 50px;
height: 500px !important;
display: flex;
align-items: center;
}
}
</style>